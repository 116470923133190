<template>
  <div class="salesRecord" ref="sale">
    <div class="contents" v-for="(item, idx) in datas" :key="idx">
       <div class="top">
         <div>订单金额：￥{{item.COPrice}}</div>
         <p class="yjj">原价金额：￥{{item.COTotalPrice}}</p>
         <p>支付状态：{{item.zt}}</p>
         <p v-if="item.tkzt">退货状态：{{item.tkzt}}</p>
       </div>
       <div class="top">
         <p class="padd-100">提交时间：{{item.COAddTimeString}}</p>
         <p>订单编号：{{item.bh}}</p>
       </div>
      <div class="bottom" :class="type?'type1':''">
        <el-button class="fn" @click="goxq(item.COrderId)">订单详情</el-button>
        <el-button v-if="item.COrderType == 'product'" class="fn" @click="spjf(item.COrderId)">售后管理</el-button>
        <!--         <el-button class="fn" v-if="!type">加工单</el-button>-->
        <el-button class="fn" @click="dydd(item.COrderId)">打印订单</el-button>
        <!--         <el-button class="fn" v-if="!type">相关咨询</el-button>-->
      </div>
     </div>
  </div>
</template>

<script>
import {Loading} from "element-ui";

export default {
  name: "salesRecord",
  data() {
    return {
      arr: [1,2,3,4,5,7,9],
      datas: []
    }
  },
  props: {
    istop: {
      default() {
        return 0
      }
    },
    idxs: {
      default() {
        return 0
      }
    },
    type: {
      default() {
        return 0;
      }
    }
  },
  watch: {
    idxs: function (newData, old) {
      if (this.datas.length < 1 && newData==2) {
        this.getData()
      }
    },
    '$store.state.physicianVisits.cancellation': function (newData) {
      if (newData) {
        this.datas = []
      }
    },
    "$store.state.users.CsUser": {
      handler(n, o) {
        if (n) {
          this.getData()
        }
      },
      deep: true
    }
  },
  created() {
    if (this.type) {
      this.getData()
    }
  },
  methods: {
    getData() {
      if (!this.$store.state.users.CsUser) {

        return
      }
      let loadingInstance = Loading.service({
        background: 'rgba(0, 0, 0, 0.7)',
        text: '加载中...'
      });
      this._api.userCurdApi.getSalesList()
      .then(res => {
        loadingInstance.close();
        if (res.GetListResult && res.GetListResult.length > 0) {
          this.datas = res.GetListResult.map(item => {
            switch (item.COPayState) {
              case "order":
                item.zt = '待支付'
                    break
              case 'payed':
                item.zt = '已支付'
                break
              case 'retail':
                item.zt = '已退款'
                break
              case 'customercancel':
                item.zt = '已取消'
                break
              case 'expire':
                item.zt = '已过期'
                break
              case 'given':
                item.zt = '已交付'
                break
              case 'sellcancel':
                item.zt = '商家取消'
                break
              case 'cancel':
                item.zt ='取消'
                break
              case 'producing':
                item.zt ='生产中'
                break
              case 'partpayed':
                item.zt ='部分支付'
                break
              default:
                item.zt = ''
                    break
            }
            switch (item.CORefundState) {
              case "refund":
                item.tkzt = '全单退货退款'
                break
              case "partrefund":
                item.tkzt = '部分退单退款'
                break
            }
            item.bh = item.COrderId.slice(0,8).toUpperCase()
            return item
          })
        }else {
          this.datas = []
        }
      })
    },
    goxq(id) {
      this.$emit('sendC', false)
      this.$store.commit('isShow/upIframeType', {
        type: 1,
        value: id
      })
      this.$router.push({path: `/Iframe?type=1&oid=${id}`})
    },
    spjf(id) {
      this.$store.commit('bases/upOrid', id)
      this.$router.push({path: '/AfterSaleManagementHome'})
    },
    dydd(id) {
      this.$router.push({path: `/Iframe?type=19&oid=${id}`})
    }
  }
}
</script>

<style scoped lang="scss">
   .salesRecord {
     //width: 9rem;
     //height: 500px;
     //overflow-y: auto;
     padding: 0 5px;
   }
   .contents {
     padding-top: 10px;
     border-bottom: 1px solid;
     padding-bottom: 10px;
     padding-left: 10px;
   }
   .contents:hover{
     background: #eeeded;
   }
   .top {
     padding-bottom: 20px;
     display: flex;
     align-items: center;
     justify-content: space-between;
     div {font-size: 16px}
     p {
       font-size: 12px;
       color: #888787;
     }
     .padd-100 {padding-right: 0.75rem}
     .yjj {
       //padding-left: 1.3rem;
       //padding-right: 1.3rem;
     }
   }
   .bottom {
     display: flex;
     justify-content: start;
     .fn {
       margin-left: 0.5rem;
       &:nth-child(1) {margin: 0;}
     }
   }
   .type1 {justify-content: center;}
</style>
